import { useFirebaseDatabase, useFirebaseValue } from '/machinery/useFirebaseDatabase'
import { StatementCard } from '/features/pageOnly/StatementCard'
import { ref, set } from 'firebase/database'
import { statements } from '/data/statements'
import { useUser } from '/machinery/FirebaseAuthProvider'
import { Loader } from '/features/buildingBlocks/Loader'
import { useReportError } from '/machinery/ReportError'

import styles from './RelevantStatementsSelection.css'

export function RelevantStatementsSelection() {
  const [{ isFetching, cards, progress }, { handleAnswer }] = useStatementStore({ initalValue: statements })

  if (isFetching) return <Loader />

  return (
    <div className={styles.component}>
      {cards.map((card, index) => {
        const { statement, icon, selected, id } = card

        return (
          <StatementCard
            key={id}
            index={index + 1}
            onAnswer={selected => handleAnswer({ selected, id: card.id })}
            total={progress.total}
            isSelected={selected}
            {...{ statement, icon }}
          />
        )
      })}
    </div>
  )
}

function useStatementStore({ initalValue }) {
  const reportError = useReportError()
  const { user } = useUser()
  const database = useFirebaseDatabase()

  const { data, isFetching } = useFirebaseValue({
    reference: ({ user, database }) => ref(database, `/users/${user.uid}/statements`),
  })

  const entries = React.useMemo(
    () => {
      if (!data) return initalValue

      return Object.entries(data).reduce(
        (result, [id, data]) => {
          result[id] = { ...result[id], ...data }
          return result
        },
        initalValue
      )
    },
    [initalValue, data]
  )

  return [
    {
      isFetching,
      cards: Object.values(entries),
      progress: {
        total: Object.values(initalValue).length,
        answered: Object.values(entries).filter(x => x.selected !== null).length
      }
    },
    {
      handleAnswer
    }
  ]

  async function handleAnswer({ selected, id }) {
    try {
      await set(ref(database, `/users/${user.uid}/statements/${id}`), { selected })
    } catch (e) {
      reportError(e)
    }
  }
}
