import { useUser } from './FirebaseAuthProvider'
import { useFirebaseApp } from '/machinery/FirebaseProvider'
import { getDatabase, onValue } from 'firebase/database'
import isEqual from 'lodash/isEqual'

export function useFirebaseDatabase() {
  const firebaseApp = useFirebaseApp()
  const database = firebaseApp ? getDatabase(firebaseApp) : null

  return database
}
export function useFirebaseValue({ reference: raw }) {
  const { user } = useUser()
  const firebaseApp = useFirebaseApp()
  const database = firebaseApp ? getDatabase(firebaseApp) : null
  const [data, setData] = React.useState(null)
  const [isFetching, setIsFetching] = React.useState(true)
  const referenceRef = useStableRef(raw)

  React.useEffect(
    () => {
      if (!database || !referenceRef || !user)
        return

      const unsubscribe = onValue(referenceRef.current({ user, database }), (snapshot) => {
        setIsFetching(false)
        snapshot.exists() ? setData(snapshot.val()) : setData(null)
      })

      return () => {
        unsubscribe()
      }
    },
    [database, referenceRef, user]
  )

  return { data, isFetching }
}

const useStableRef = (value) => {
  const refRef = React.useRef(value)

  React.useEffect(() => {
    if (!isEqual(value, refRef.current)) {
      refRef.current = value
    }
  }, [value])

  return refRef
}
