import { useClientConfig } from '/machinery/ClientConfig'
import { FirebaseAuthProvider } from '/machinery/FirebaseAuthProvider'
import { FirebaseProvider } from '/machinery/FirebaseProvider'

export function FirebaseContext({ children }) {
  const { firebase, reCaptchaSiteKey } = useClientConfig()

  return (
    <FirebaseProvider config={firebase} name={firebase.name} {...{ reCaptchaSiteKey }}>
      <FirebaseAuthProvider>
        {children}
      </FirebaseAuthProvider>
    </FirebaseProvider>
  )
}
