import { Icon } from './Icon'
import { IconArrowRight, IconArrowLeft } from '/icons'

import styles from './Button.css'

export function Button({ color = undefined, icon = undefined, iconPosition = 'right', onClick = undefined, layoutClassName = undefined, type = 'button', disabled = undefined, children }) {
  /** @type {any} */
  return (
    <ButtonBase tag='button' {...{ color, icon, iconPosition, onClick, layoutClassName, type, disabled, children }} />
  )
}

export function ButtonLink({ href, color = undefined, icon = undefined, iconPosition = 'right', onClick = undefined, layoutClassName = undefined, children }) {
  /** @type {any} */
  return (
    <ButtonBase tag='a' {...{ href, color, icon, iconPosition, onClick, layoutClassName, children }} />
  )
}

function ButtonBase({ color = undefined, icon = IconArrowRight, iconPosition = 'right', tag = 'button', onClick = undefined, href = undefined, type = undefined, layoutClassName, disabled = undefined, children }) {
  /** @type {any} */
  const TagBase = tag
  return (
    <TagBase
      className={cx(
        styles.componentBase,
        layoutClassName,
        color && styles[color],
        iconPosition === 'left' && styles.hasIconLeft,
        icon === IconArrowRight && styles.hasIconArrowRight,
        icon === IconArrowLeft && styles.hasIconArrowLeft
      )}
      {...{ onClick, type, href, disabled }}
    >
      {children}
      {icon && (
        <Icon {...{ icon }} layoutClassName={styles.iconLayout} />
      )}
    </TagBase>
  )
}
