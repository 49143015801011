import { RelevantStatementsSelection } from './RelevantStatementsSelection'
import { FirebaseContext } from '/domain/FirebaseContext'

export default function RelevantStatementsSelectionApp() {
  return (
    <FirebaseContext>
      <RelevantStatementsSelection />
    </FirebaseContext>
  )
}
