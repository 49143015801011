import { Card } from '/features/pageOnly/Card'
import { Pill } from '/features/buildingBlocks/Pill'
import { SpeechBorder } from '/features/buildingBlocks/SpeechBorder'
import { Heading42 } from '/features/buildingBlocks/Heading'
import { Button } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { YesNoContainer } from '/features/buildingBlocks/YesNoContainer'
import { IconCheckmarkLarge, IconCross } from '/icons'

import styles from './StatementCard.css'

export function StatementCard({ index, total, statement, icon: icon, isSelected, onAnswer }) {
  return (
    <div className={styles.component}>
      <Card layoutClassName={styles.cardLayout}>
        <div className={styles.layout}>
          <Pill layoutClassName={styles.pillLayout}>
            Stelling {index} van {total}
          </Pill>
          <div className={styles.statement}>
            <StatementIllustration {...{ icon }} />
            <Heading42 title={`‘${statement}’`} layoutClassName={styles.headingLayout} />
            <SpeechBorder />
          </div>
          <YesNoContainer>
            <Button
              color={getButtonColor('yes')}
              icon={IconCheckmarkLarge}
              iconPosition='left'
              onClick={() => onAnswer(true)}>
              Ja
            </Button>
            <Button
              color={getButtonColor('no')}
              icon={IconCross}
              iconPosition='left'
              onClick={() => onAnswer(false)}
            >
              Nee
            </Button>
          </YesNoContainer>
        </div>
      </Card>
    </div>
  )

  function getButtonColor(buttonType) {
    if (isSelected === undefined) {
      return 'blue'
    }
    if (buttonType === 'yes') {
      return isSelected ? 'green' : 'gray'
    }
    return isSelected ? 'gray' : 'green'
  }
}

function StatementIllustration({ icon }) {
  return (
    <div className={styles.componentStatementIllustration}>
      <Icon {...{ icon }} layoutClassName={styles.illustrationLayout} />
    </div>
  )
}
