import { initializeApp, getApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const FirebaseContext = React.createContext(null)

export function useFirebaseApp() {
  return React.useContext(FirebaseContext)
}

export function FirebaseProvider({ name, config, reCaptchaSiteKey, children }) {
  const firebaseApp = useFirebaseAppInstance({ name, config })

  React.useEffect(
    () => {
      if (!firebaseApp) return
      initializeAppCheck(firebaseApp, { provider: new ReCaptchaV3Provider(reCaptchaSiteKey) })
    },
    [firebaseApp, reCaptchaSiteKey]
  )

  return <FirebaseContext.Provider value={firebaseApp}>{children}</FirebaseContext.Provider>
}

function useFirebaseAppInstance({ name, config }) {
  const [firebaseApp, setFirebaseApp] = React.useState(null)
  React.useEffect(
    () => {
      let firebaseApp

      try {
        firebaseApp = getApp(name)
      } catch (err) {
        firebaseApp = initializeApp(config, name)
      }

      setFirebaseApp(firebaseApp)
    },
    [name, config]
  )

  return firebaseApp
}
