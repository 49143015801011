import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth'
import { useFirebaseApp } from './FirebaseProvider'

const FirebaseAuthContext = React.createContext(null)

export function useUser() {
  return React.useContext(FirebaseAuthContext)
}

export function FirebaseAuthProvider({ children }) {
  const firebaseApp = useFirebaseApp()

  const [error, setError] = React.useState(null)
  const [{ isLoggedIn, user }, setUser] = React.useState({ user: {}, isLoggedIn: false })
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    if (!firebaseApp) return

    const auth = getAuth(firebaseApp)
    if (!auth.currentUser) {
      signInAnonymously(auth)
        .then(({ user }) => {
          setUser({ user, isLoggedIn: true })
          setIsLoading(false)
        })
        .catch(error => {
          setError(error)
          setIsLoading(false)
        })
    }

    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUser({ user, isLoggedIn: true })
      } else {
        setUser({ user: {}, isLoggedIn: false })
      }

      setIsLoading(false)
    })

    return () => unsubscribe()
  }, [firebaseApp])

  const value = { isLoading, error, isLoggedIn, user }
  return <FirebaseAuthContext.Provider {...{ value }}>{children}</FirebaseAuthContext.Provider>
}
